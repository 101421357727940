#my-pic{
    max-width: 200px;
    max-height: 200px;
    border-radius: 10px;
    margin: 10px;
}

div#hero{
    display:flex;
    justify-content:flex-start;
    background-image: linear-gradient(to right, rgb(120, 83, 255) , rgb(24, 83, 192));
    max-width: 700px;
    border-radius: 10px;
    color:white;
    padding: 0px 0px 8px 0px;
}

div#hero-text{
    
    text-align: left;
    
}

.Endorsement{
    text-align: center;
}

figcaption{
    text-align: center;
    font-size: .8rem;
}