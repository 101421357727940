
.exp-header{
    display:flex;
    justify-content: space-between;
}

.company-img{
    max-width: 170px;
    max-height: 70px;
    margin: 0;
    display: block;
}

.job-title{
    font-size: 1.5em;
    font-weight: bold;
    color: white;
}
.experience{
    max-width: 550px;
    background-image: linear-gradient(to right, rgb(96, 106, 255) , rgb(61, 139, 241));
    border-radius: 10px;
    padding-left: 15px;
    padding-bottom: 8px;
    padding-right: 15px;
    padding-top: 8px;
    margin: 5px;
    margin-top: 7px;
}

.exp-dates{
    margin: 0;
}