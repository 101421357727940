a{
    text-decoration:none;
}

.project-component{
    max-width:500px;
    max-height: 500px;
    /*background-image: linear-gradient(to right, rgb(100, 111, 255) , rgb(217, 132, 228));
    color:white;*/
    
    margin-top: 7px;
    border-radius: 10px;
    padding-left: 15px;
    padding-bottom: 8px;
    padding-top: 5px;

    border-color: white;
    border-width: 2px;
    border-style: solid;
    background-image: linear-gradient(to right, rgb(189, 113, 199, 0.5) , rgb(100, 111, 255,0.5));
}

p, h2{
    color:white;
}

.project-image{
    max-width: 300px;
    max-height: 150px;
    border-radius: 10px;
    margin-right: 8px;
}

.project-text h2{
    margin-bottom: 5px;
    margin-top: 0px;
}

.project-text p{
    margin-top: 0px;
}

.project-content{
    display:flex;
}

.project-component:hover{
    background-image: linear-gradient(to right, rgb(189, 113, 199) , rgb(100, 111, 255));
    
}