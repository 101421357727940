.App {
  margin-left: 2%;
  margin-top: 100px;
}

@media (max-width:  1100px) {
  .App {
    margin-left: 0;
  }

  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  div{
    
    
  }

  div.section{
    min-width: 700px;
    
  }
}